import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import TermineMargin from "../../components/termine-margin"
import ElternInfoContentHeader from "../../components/elterninfo/elterninfo_contentHeader"
import ElternInfoSubmenu from "../../components/elterninfo/elterninfo_submenu"

const ganztagsangebot = () => (
  <Layout>
    <SEO title="Ganztagsangebot"/>

    <section id="content_2_schule">
      <ElternInfoContentHeader headline="GANZTAGSANGEBOT" motto="OFFENER GANZTAG LUMMERLAND"/>
      <ElternInfoSubmenu />

      <article id="con_2_rechts">

        <h3 className="small_sub">
          <span style={{color:"#007c34"}}>Offener Ganztag Lummerland der Stadt L&ouml;hne</span>
        </h3>

        <p className="text">
          <span style={{fontWeight: "bold"}}>In der OGS besuchen Kinder aus allen Jahrg&auml;ngen das Ganztagsangebot.<br />
Von 7.40 Uhr bis 11.30 Uhr unterrichten und betreuen Lehrkr&auml;fte der Schule die Kinder.<br />
Ab 11.30 Uhr bis 16.00 Uhr (verl&auml;ngerte &Ouml;ffnungszeit bis 16.30 Uhr) werden sie von den p&auml;dagogischen Fachkr&auml;ften der OGS betreut.</span><br /><br />
          Der Offene Ganztag in L&ouml;hne ist jeweils eine Woche in den Oster- und Herbstferien und eine Woche in den
          Sommerferien ge&ouml;ffnet. Zwischen Weihnachten und Neujahr sind alle OGS-Einrichtungen der Stadt L&ouml;hne
          geschlossen.
          <br /><br />
            Der Tr&auml;ger der au&szlig;erunterrichtlichen Angebote ist das Jugendamt der Stadt L&ouml;hne. Der Offene
            Ganztag wird
            auf Grundlage von Kooperationsvereinbarungen zwischen Schule und Kinder- und Jugendhilfe gestaltet. Der
            Offene Ganztag ist eine familienerg&auml;nzende Einrichtung, in der die Kinder Sicherheit, Geborgenheit und
            Lernanreize erfahren. Sie schlie&szlig;en hier Freundschaften und &uuml;ben partnerschaftliches, soziales
            Verhalten. Selbstst&auml;ndigkeit, Verantwortungsgef&uuml;hl, Kritikf&auml;higkeit und angemessene
            Konfliktl&ouml;sungen
            werden entwickelt.
            <br /><br />
              Die OGS bietet den Kindern Orientierungsm&ouml;glichkeiten, verschiedene Sichtweisen und Perspektiven in
              vielf&auml;ltigen Angeboten und Aktivit&auml;ten. (Projekte, Spiel und Spa&szlig;,
              Sport, &Ouml;ffentlichkeit, Kultur usw.)
              <br /><br />
                <span style={{color:"#007C34"}}>&#9679;</span> Von Montag bis Donnerstag erledigen die Kinder ihre
                Hausaufgaben in der OGS. Unterst&uuml;tzung erhalten sie von den Fachkr&auml;ften der OGS sowie
                Lehrkr&auml;ften der
                Schule.<br /><br />
                <span style={{color:"#007C34"}}>&#9679;</span> Nach Schulschluss erhalten alle Kinder t&auml;glich eine
                warme
                Mahlzeit, Getr&auml;nke und nachmittags einen Imbiss.
                <br /><br />
                  <span style={{fontWeight: "bold"}}>Die Elternbeitr&auml;ge richten sich nach der Elternbeitragssatzung der Stadt L&ouml;hne und sind nach dem Bruttojahreseinkommen gestaffelt. Sie werden monatlich an die Stadt L&ouml;hne entrichtet.</span>
                  <br /><br />

                    <h4 className="xsmall_sub">Ansprechpartner:</h4><br />
                    <p className="text">
                      <span style={{fontWeight: "bold"}}>Frau Brennemann (Einrichtungsleitung)</span><br />
                      <span style={{fontWeight: "bold"}}>Telefon:</span> 05732 9000267 oder 0151 50712949
                    </p>
        </p>

      </article>

      <TermineMargin/>
    </section>
  </Layout>
)

export default ganztagsangebot